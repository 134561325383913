.selectInput {
  background: hsla(0, 0%, 100%, 0.8);
  border-radius: 5px;
  height: 44px;
  padding: 0 10px;
  color: #333;
  margin: 0 0 20px;
  border: 1px solid #d6d6d6;
  font-size: 15px;
  font-family: arial;
  cursor: pointer;
  width: 100%;
}
