.container-showing-steps {
  justify-content: center;
  display: flex;
  margin-bottom: -20px;

  @media only screen and (max-width: 768px) {
    padding: 0 10px;
  }

  .showing-steps {
    max-width: 700px;
    width: 100%;
    display: block;
    font-size: 14px;
    color: #333;
  }
}

.signup-form {
  max-width: 100% !important;
  flex-direction: row !important;
}

.wizard-header {
  display: flex;
  margin: auto;
  width: 700px;

  button.button-back {
    border: none;
    background: none;
    font-size: 15px;
    color: #333;
    display: flex;

    @media only screen and (max-width: 720px) {
      padding: 0 10px;
    }

    svg {
      margin-right: 5px;
    }
  }
}

.row {
  .subtitle {
    padding: 25px 0;
    color: #5d5c62;
    font-size: 16px;
  }

  .wizard-footer {
    display: flex;
    justify-content: center;
    margin: 25px 0px 20px;
  }

  .form-checkbox {
    input[type='checkbox'] {
      vertical-align: middle;
      position: relative;
      top: 8px;
    }
  }
}

.step-progress {
  height: 100%;

  .multi-step {
    height: 100%;
  }
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin-top: 22.5px;
  max-width: 700px;
  margin: 30px auto;
  margin-bottom: -30px;
  background: #afafaf;
  border-radius: 20px;

  @media only screen and (max-width: 720px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 5.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
}

ol.progtrckr li:nth-child(1) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

ol.progtrckr li:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

ol.progtrckr li span {
  text-align: center;
  font-size: 15px;
  color: rgba(25, 25, 32, 0.7);
  line-height: 35px;
  margin-top: 0px;
  text-transform: uppercase;
  font-weight: bold;
  display: none;
}

.input-check {
  width: 16px !important;
  height: 16px !important;
  margin-right: 5px !important;
  position: relative !important;
}

.progtrckr em {
  font-style: normal;
  background: white;
  border-radius: 50%;
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  color: gray;
  border: 3px solid #afafaf;
  margin-top: -18px;
  display: none;
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-top: 28px solid #afafaf;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-top: 28px solid var(--brand-primary);
  border-radius: 0 15px 15px 0;

  em {
    background: #ffffff;
    border-color: var(--brand-primary);
    color: var(--brand-primary);
  }
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-top: 28px solid var(--brand-primary);

  em {
    background: var(--brand-primary);
    border-color: transparent;
    color: white;
  }
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.mailcheck {
  .mailcheck-btn {
    padding: 0 !important;
    opacity: 1 !important;
    border: none !important;
    background: none !important;
    font-size: inherit !important;
    font-style: italic !important;
    color: #335f4a !important;
    cursor: pointer !important;
    text-decoration: underline !important;
    text-underline-position: under !important;
  }
}
